import React from "react";

const RandevuPage = () => {
  return (
    <div class="flex items-center justify-center bg-sky-900 py-16">
      <div class="container">
        <div class="bg-white rounded-lg shadow-lg p-5 md:p-20 mx-2">
          <div class="text-center">
            <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
              The Gentleman Studio
            </h2>
            <h3 class="text-xl md:text-3xl mt-10">
              Çok yakında randevularınızı online alabileceksiniz
            </h3>
            <p class="text-md md:text-xl mt-10">
              Randevu sistemimiz yakında hizmetinizde olacak. O zamana kadar
              uzman ekibimizle iletişime geçerek randevu almak için bizi arayın:
            </p>
          </div>
          <div class="flex flex-wrap mt-10 justify-center">
            <section className="font-chakra py-12 bg-neutral-800 text-gray-100 rounded-lg">
              <div className="container flex flex-col items-center justify-center p-4 mx-auto sm:p-10">
                <div className="flex flex-row flex-wrap-reverse justify-center mt-8 mb-8">
                  <div className="flex flex-col justify-center w-full px-8 mx-6 my-12 text-center rounded-md md:w-96 lg:w-80 xl:w-64 bg-gray-100 text-gray-800">
                    <img
                      alt=""
                      className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full bg-gray-500"
                      src="https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/mustafa.jpg?alt=media&token=fb35494f-3626-4a4e-b052-94cf1adb89a2"
                    />
                    <div className="flex-1 my-4">
                      <p className="text-xl font-semibold leading-snug">
                        Mustafa Yunak
                      </p>
                      <p>Masör</p>
                    </div>
                    <div className="flex items-center justify-center p-3 space-x-3 border-t-2">
                      {/* <a
                  rel="noopener noreferrer"
                  href="#"
                  title="Email"
                  className="text-gray-900 hover:text-orange-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                  </svg>
                </a> */}
                      <a
                        rel="noopener noreferrer"
                        href="tel:+905457404362"
                        title="Instagram"
                        className="text-gray-900 hover:text-orange-700"
                      >
                        <svg
                          fill="currentColor"
                          width="800px"
                          height="800px"
                          viewBox="-2 -2 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          preserveAspectRatio="xMinYMin"
                          class=""
                          className="w-12 h-12"
                        >
                          <path d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z" />
                        </svg>
                      </a>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center w-full px-8 mx-6 my-12 text-center rounded-md md:w-96 lg:w-80 xl:w-64 bg-gray-100 text-gray-800">
                    <img
                      alt=""
                      className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full bg-gray-500"
                      src="https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/batin.jpg?alt=media&token=cd1ed35d-8ddb-46d0-bbd6-b454c7229c74"
                    />
                    <div className="flex-1 my-4">
                      <p className="text-xl font-semibold leading-snug">
                        Batın Ayvaz
                      </p>
                      <p>Kuaför / Manikür, Pedikür ve Cilt Bakımı Uzmanı</p>
                    </div>
                    <div className="flex items-center justify-center p-3 space-x-3 border-t-2">
                      {/* <a
                  rel="noopener noreferrer"
                  href="#"
                  title="Email"
                  className="text-gray-900 hover:text-orange-700"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                  </svg>
                </a> */}
                      <a
                        rel="noopener noreferrer"
                        href="tel:+905302026120"
                        target="_blank"
                        title="Phone"
                        className="text-gray-900 hover:text-orange-700"
                      >
                        <svg
                          fill="currentColor"
                          width="800px"
                          height="800px"
                          viewBox="-2 -2 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          preserveAspectRatio="xMinYMin"
                          class=""
                          className="w-12 h-12"
                        >
                          <path d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center w-full px-8 mx-6 my-12 text-center rounded-md md:w-96 lg:w-80 xl:w-64 bg-gray-100 text-gray-800">
                    <img
                      alt=""
                      className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full bg-gray-500"
                      src="https://firebasestorage.googleapis.com/v0/b/the-gentleman-studio.appspot.com/o/abdurrahman.jpg?alt=media&token=cc47bcbe-14ab-413d-91a8-8f18f3684ddb"
                    />
                    <div className="flex-1 my-4">
                      <p className="text-xl font-semibold leading-snug">
                        Abdurrahman Özbostan
                      </p>
                      <p>Kuaför / Masör / Cilt Bakımı Uzmanı</p>
                    </div>
                    <div className="flex items-center justify-center p-3 space-x-3 border-t-2">
                      <a
                        rel="noopener noreferrer"
                        href="tel:+905326130354"
                        target="_blank"
                        title="Instagram"
                        className="text-gray-900 hover:text-orange-700"
                      >
                        <svg
                          fill="currentColor"
                          width="800px"
                          height="800px"
                          viewBox="-2 -2 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          preserveAspectRatio="xMinYMin"
                          class=""
                          className="w-12 h-12"
                        >
                          <path d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RandevuPage;

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z" />
</svg>;
