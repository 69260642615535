import React, { useState } from "react";
import Navigation from "../../components/navigation/Navigation";
import RandevuPage from "../../components/randevupage/RandevuPage";

const Randevu = () => {
  const [activeMenu, setActiveMenu] = useState(0);

  const handleMenuChange = (index) => {
    if (index === 1) {
      window.location.href = "https://www.thegentlemanstudio.com";
    } else {
      setActiveMenu(index);
    }
  };
  const renderContent = () => {
    switch (activeMenu) {
      case 0:
        return <RandevuPage />;
      default:
        return null;
    }
  };
  return (
    <div className="justify-center h-screen bg-sky-900">
      <Navigation activeMenu={activeMenu} onMenuChange={handleMenuChange} />
      {renderContent()}
    </div>
  );
};

export default Randevu;
