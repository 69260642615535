import logo from './logo.svg';
import './App.css';
import Randevu from './pages/randevu/Randevu';

function App() {
  return (
    <div className="App">
      <Randevu />
    </div>
  );
}

export default App;
